//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import uri from 'url';

export default {
  name: 'Pagination',
  props: {
    total: {
      // 总条数
      type: Number,
      default: 0,
    },
    pageSize: {
      // 每一页的个数
      type: Number,
      default: 10,
    },
    pagerCount: {
      // 页码按钮的数量, 默认7，只取从5到21之间的奇数，如果传入偶数，自动减1
      type: Number,
      default: (val) => {
        if (!val || isNaN(val) || val < 5 || val > 21) return 9;
        let num = 7;
        for (let i = 5; i <= 21; i++) {
          if (i % 2 !== 0) {
            if (val >= i && val < i + 2) {
              num = i;
            }
          }
        }
        return num;
      },
    },
    currentPage: {
      // 当前页码
      type: Number,
      default: 1,
    },
    pageCount: {
      // 总页数
      type: Number,
      default: 0,
    },
    firstLink: {
      // 第一页的link链接
      type: String,
      default: '',
    },
    link: {
      // 其他页的link链接
      type: String,
      default: '',
    },
    linkPath: {
      // 分页页码前的路径，可以为空，eg：p1/p-1/page1/page-1中1前面的部分，默认为/page
      type: String,
      default: '',
    },
    useALink: {
      // 服务端显然是否需要改为使用a标签跳转，默认使用nuxt-link单页跳转
      type: Boolean,
      default: false,
    },
    ssr: {
      // 默认为服务端渲染使用nuxt-link跳转，为false时使用span通过事件传递出去
      type: Boolean,
      default: true,
    },
    confirmBtn: {
      // 是否显示确认按钮,默认不显示
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nowPage: 1, // 当前页码
      redirectNum: 1, // 前往页码
    };
  },
  computed: {
    totalPage() {
      // 计算总页码
      return this.pageCount > 0
        ? this.pageCount
        : Math.ceil(this.total / this.pageSize);
    },
    jumpNum() {
      // jumpNum是根据pagerCount的计算出现省略号折叠的页数
      return (this.pagerCount + 3) / 2;
    },
    addNum() {
      // addNum表示当出现折叠省略号时中间应该额外增加的数量的一半
      return (this.pagerCount - 3) / 2;
    },
    pageList() {
      // 计算中间页生成的页码
      let arr = [];
      if (this.totalPage <= 4) {
        // 头两页和尾两页是固定的，不用生成中间页
        return [];
      } else if (this.totalPage > 4 && this.totalPage <= this.pagerCount + 1) {
        arr = Array.from(new Array(this.totalPage - 1).keys()).slice(3);
      } else if (this.totalPage > this.pagerCount + 1) {
        if (this.nowPage < this.jumpNum) {
          arr = Array.from(new Array(this.pagerCount).keys()).slice(3);
        } else if (
          this.nowPage >= this.jumpNum &&
          this.nowPage < this.totalPage - this.addNum - 1
        ) {
          // 从jumpNum页数开始折叠
          const maxNum = this.nowPage + this.addNum;
          arr = Array.from(new Array(maxNum + 1).keys()).slice(
            this.nowPage - this.addNum
          );
        } else if (this.nowPage >= this.totalPage - this.addNum - 1) {
          // 最后依据addNum的值判断不再显示省略号并且页码数是固定的
          const maxNum = this.totalPage;
          const reduceNum = this.addNum * 2 + 1;
          arr = Array.from(new Array(maxNum - 1).keys()).slice(
            this.totalPage - reduceNum
          );
        }
      }
      // console.log(arr, 'arr');
      return arr;
    },
  },
  watch: {
    currentPage(newval) {
      this.nowPage = newval;
      this.redirectNum = newval;
    },
  },
  created() {
    this.nowPage = this.currentPage;
    this.redirectNum = this.nowPage;
  },
  methods: {
    uriFormat: uri.format,
    prevPage() {
      if (this.nowPage <= 1) return false;
      this.nowPage -= 1;
      this.redirectNum = this.nowPage;
      this.$emit('changePager', this.nowPage);
    },
    changePage(page) {
      if (page > 0) {
        this.nowPage = page;
        this.redirectNum = page;
        this.$emit('changePager', this.nowPage);
      }
    },
    jumpPage(page) {
      const str = String(page.target.value);
      if (str.charAt(0) >= 1) {
        const val = parseInt(str);
        if (val <= this.totalPage) {
          this.redirectNum = val;
        } else {
          this.redirectNum = this.totalPage;
        }
      } else {
        this.redirectNum = 1;
      }
      this.nowPage = Number(this.redirectNum);
      this.$emit('changePager', this.nowPage);
    },
    nextPage() {
      if (this.nowPage >= this.totalPage) return false;
      this.nowPage += 1;
      this.redirectNum = this.nowPage;
      this.$emit('changePager', this.nowPage);
    },
    jumpFivePage(type) {
      if (type === 'prev') {
        this.nowPage -= this.pagerCount - 2;
        if (this.nowPage <= 1) this.nowPage = 1;
      } else {
        this.nowPage += this.pagerCount - 2;
        if (this.nowPage >= this.totalPage) this.nowPage = this.totalPage;
      }
      this.redirectNum = this.nowPage;
      this.$emit('changePager', this.nowPage);
    },
  },
};
