























import _ from 'lodash';
import Vue from 'vue';
import { mapState } from 'vuex';
import MenuItem from './components/MenuItem.vue';
import { $cookies } from '~/util/api';
import { ResponseCode } from '~/util/constant';

export default Vue.extend<any, any, any>({
  name: 'Sidebar',
  components: {
    MenuItem,
  },
  data() {
    return {
      menu: [
        {
          label: '签证',
          title: '签证',
          icon: 'el-icon-bank-card',
          path: '/visa-0-0-0-0-1/',
          activePath: 'visa-(\\d+)-(\\d+)-1',
        },
        {
          label: '酒店',
          title: '酒店',
          icon: 'el-icon-office-building',
          path: `${this.$config.HOTEL}`,
          activePath: 'hotel',
        },
        {
          label: '机票',
          title: '机票',
          icon: 'el-icon-s-promotion',
          path: '/airTicket/list', // 机票首页 /airTicket
          activePath: 'airTicket',
        },
      ],
      keyword: '',
      credit: 0,
      isCollapse: false,
      w: 150,
    };
  },
  computed: {
    ...mapState({
      isLogin: (state: any) => state.global.isLogin,
    }),
  },
  created() {
    this.$emit('on-change', this.w);
  },
  mounted() {
    // this.handleSidebar();
  },
  methods: {
    /**
     * 处理是否显示左侧侧边栏
     * @returns {any}
     */
    handleSidebar() {
      const hideSidebar = ['/visa/']; // 不显示左侧侧边栏的页面path
      const pathStr = this.$route.path;

      const result = hideSidebar.some((item) => !!pathStr.includes(item));
      // console.log(71, pathStr.indexOf('/visa/'), result);
      if (result) {
        const sidebar: any = document.querySelector('.sidebar-container');
        sidebar.style.display = 'none';
      }
    },
    toPath(url: string) {
      window.open(url, '_blank');
    },
    handleLogin() {
      this.$login.show();
    },
    async handleLogout() {
      const res: any = await this.$axios.get('/api/auth/logout');
      if (res.code === ResponseCode.SUCCESS) {
        $cookies.removeAll();
        this.$store.commit('global/setIsLogin', false);
      }
    },
    handleSearch: _.debounce(
      function () {
        // @ts-ignore
        const { keyword } = this;
        // if (!isLogin) {
        //   return handleLogin();
        // }
        if (keyword) {
          window.open(`/exhibition/?keyword=${keyword}`, '_blank');
        }
      },
      3000,
      { leading: true }
    ),
    handleFold() {
      this.isCollapse = !this.isCollapse;

      this.w = this.isCollapse ? 63 : 150;
      this.$emit('on-change', this.w);
    },

    handleOpen(key: any, keyPath: any) {
      console.log(key, keyPath);
    },
    handleClose(key: any, keyPath: any) {
      console.log(key, keyPath);
    },
  },
});
